<template>
  <div class="px-md-5 px-1 mt-5">
    <div class="family headtitle" style="margin-top: 1rem; font-size: 25px">
      账号设置
    </div>
    <div class="position-relative headimg">
      <img :src="user_info.avatar" width="80px" height="80px" alt="" />
      <div class="p-2 text-center position-absolute bottom-0 right-0 left-0 cursor-pointer">
        <el-upload class="" :action="`*`" :before-upload="beforeUpload">
          <img src="@/assets/image/userCenter/edit_icon.png" class="edit-icon" />
        </el-upload>
      </div>
    </div>
    <div class="setting-box">
      <div class="setting-item">
        <div>昵称</div>
        <div @click="openNickNameDialog">
          <span>{{ user_info.name }}</span>
          <span style="color: RGBA(130, 161, 197, 1);">&gt;</span>
        </div>
      </div>
      <div class="setting-item">
        <div>密码</div>
        <div @click="openPasswordDialog">
          <span>********</span>
          <span style="color: RGBA(130, 161, 197, 1);">&gt;</span>
        </div>
      </div>
      <div class="setting-item">
        <div>手机</div>
        <div>
          <span>{{ user_info.mobile }}</span>
          <span style="color: RGBA(130, 161, 197, 1);">&gt;</span>
        </div>
      </div>
      <div class="setting-item">
        <div>邀请人</div>
        <div @click="openInviterDialog">
          <span>{{ user_info.invite_code }}</span>
          <span style="color: RGBA(130, 161, 197, 1);">&gt;</span>
        </div>
      </div>
    </div>
    <!-- vueCropper 剪裁图片实现-->
    <el-dialog class="cropper-dialog" width="600px" :close-on-click-modal="false" :visible.sync="dialogVisible"
      append-to-body>
      <div class="cropper-content d-md-flex d-block justify-content-center align-items-center">
        <div class="cropper" style="text-align: center">
          <vueCropper @real-time="realTime" ref="cropper" :img="option.img" :outputSize="option.size"
            :outputType="option.outputType" :info="true" :full="option.full" :canMove="option.canMove"
            :canMoveBox="option.canMoveBox" :original="option.original" :autoCrop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth" :auto-crop-height="option.autoCropHeight" :fixed="option.fixed"
            :fixedNumber="option.fixedNumber" :centerBox="option.centerBox" :infoTrue="option.infoTrue"
            :fixedBox="option.fixedBox"></vueCropper>
        </div>
        <div class="cropper d-none justify-content-between align-items-center text-center">
          <div>
            <div class="show-preview" :style="{
              width: previews.w + 'px',
              height: previews.h + 'px',
              overflow: 'hidden',
              margin: '5px',
            }">
              <div :style="previews.div">
                <img width="100px" height="100px" :src="previews.url" :style="previews.img" />
              </div>
            </div>
          </div>
          <div>
            <div class="show-preview rounded-pill" :style="{
              width: previews.w + 'px',
              height: previews.h + 'px',
              overflow: 'hidden',
              margin: '5px',
            }">
              <div :style="previews.div">
                <img class="" width="100px" height="100px" :src="previews.url" :style="previews.img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer d-flex justify-content-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
      </div>
    </el-dialog>
    <!-- 修改昵称弹窗 -->
    <div class="nick-name-dialog" v-if="showNickName">
      <div class="change-nick-name family">修改昵称</div>
      <input type="text" v-model="name" placeholder="请输入昵称" />
      <button @click="set_name">保存</button>
      <van-icon name="cross" @click="showNickName = false" class="icon-cross" />
    </div>
    <!-- 修改密码弹窗 -->
    <div class="password-dialog" v-if="showpassword">
      <div class="change-password family">修改密码</div>
      <input type="text" placeholder="请输入手机号" class="telephone" />
      <div class="code">
        <span class="input-code">请输入验证码</span>
        <span class="get-code">获取验证码</span>
      </div>
      <input type="text" placeholder="请输入密码" class="input-password" />
      <input type="text" placeholder="确认密码" class="confirm-password" />
      <button @click="showpassword = false">保存</button>
      <van-icon name="cross" @click="showpassword = false" class="cross-icon" />
    </div>
    <!-- 绑定邀请人 -->
    <div class="bind-inviter" v-if="showInviter">
      <div class="bind-invition family">绑定邀请人</div>
      <input type="text" v-model="invite_code" placeholder="请输入邀请码" />
      <button @click="getSet_inviter">保存</button>
      <van-icon name="cross" @click="showInviter = false" class="icon-cross" />
    </div>
  </div>
</template>

<script>
import bottomBg from "@/components/bottomBg";
import pay from "./common/pay";
import income from "./common/income";
import { mapState } from "vuex";
import $api from "@/api/user/index";
import StorageList from "./common/StorageList";
import TaskIn from "@/views/TaskIn/index";
export default {
  name: "index",
  components: {
    StorageList,
    bottomBg,
    pay,
    income,
    TaskIn,
  },
  data() {
    return {
      activeName: "user",
      tableData: [],
      StorageList: [],
      user: {
        info: 1,
      },
      passwordShow: false,
      getCodeSeond: "获取验证码",
      InvitationCode: "",
      steam_url: "",
      form: {
        mobile: "",
        password: "",
        verify: "",
      },
      invite_code: "",
      inviter: "",
      name: "",
      dialogVisible: false,
      loading: false,
      option: {
        canMove: true,
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 100, // 默认生成截图框宽度
        autoCropHeight: 100, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      previews: {},

      total: 0,
      StorageListtotal: 0,
      pageSize: 0,
      StorageListpageSize: 0,
      old: "",
      records: [],
      recordstotal: 0,
      recordspageSize: 0,
      showNickName: false,
      showpassword: false,
      showInviter: false,
    };
  },
  computed: {
    money() {
      let benr = 0;
      this.StorageList.forEach((item) => {
        benr += item.bean * 1;
      });
      return benr.toFixed(2);
    },
    recyleMoney() {
      let benr = 0;
      this.StorageList.forEach((item) => {
        if (item.isShow == true) {
          benr += item.bean * 1;
        }
      });
      return benr.toFixed(2);
    },
    ...mapState(["user_info", "web_info"]),
  },
  created() {
    let { name } = this.$route.query;
    if (name) {
      this.activeName = name;
    }
    setTimeout(() => {
      this.name = this.user_info.name;
      this.steam_url = this.user_info.steam_url;
      this.InvitationCode = this.user_info.invite_code;
    }, 500);
  },
  mounted() {
    this.getRecharge_record();
    this.getUsetChange_records();
  },
  methods: {
    tabClickBack() {
      console.log("asdsada");
    },
    loginOut() {
      this.$store.commit("LOGIN_IS_SHOW", true);
      this.$store.dispatch("removeInfo");
      this.$router.push("/");
    },
    handleClick(tab, event) {
      console.log("tab, event");
      console.log(tab, event);
      if (tab.name == "back") {
        this.$router.push("/user/info");
      }
      if (tab.name == "old") {
        this.getStorage();
      }
    },
    async getStorage(page = 1, status = 1) {
      try {
        let { data, code } = await $api.getStorage(page, status);
        if (code == 500) return;
        data.data.forEach((item) => {
          if (item.back_message != "") {
            console.log(123);
          }
          item.isShow = false;
        });
        this.StorageList = data.data;
        this.StorageListtotal = data.total;
        this.StorageListpageSize = data.per_page;
      } catch (e) {
        console.log(e);
      }
    },
    async getRecharge_record(page = 1) {
      try {
        let { data, code } = await $api.getRecharge_record(page);
        if (code == 500) return;
        this.tableData = data.data;
        this.total = data.total;
        this.pageSize = data.per_page;
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async getUsetChange_records(page = 1) {
      try {
        let { data, code } = await $api.getUsetChange_records(page);
        if (code == 500) return;
        this.records = data.data;
        this.recordstotal = data.total;
        this.recordspageSize = data.per_page;
      } catch (e) {
        console.log(e);
        return;
      }
    },
    currentChangeIncome(val) {
      this.getUsetChange_records(val);
      window.scrollTo(0, 0);
    },
    currentChange(val) {
      this.getStorage(val);
      window.scrollTo(0, 0);
    },
    async recycle() {
      let arr = [];
      try {
        this.StorageList.forEach((item) => {
          if (item.isShow) {
            arr.push(item.id);
          }
        });
        let { message, code } = await $api.getCash({ data: arr });
        if (code == 500) return;
        if (code == 200) {
          this.$notify({
            title: "成功",
            message: message,
            type: "success",
            position: "top-left",
          });
        }

        this.getStorage();
        this.$store.dispatch("userInfo");
      } catch (e) {
        console.log(e);
      }
    },
    async extract() {
      let arr = [];
      this.StorageList.forEach((item) => {
        if (item.isShow) {
          arr.push(item.id);
        }
      });
      try {
        let { message, code } = await $api.getExtract({ data: arr });
        if (code == 500) return;
        if (code == 200) {
          this.$notify({
            title: "成功",
            message: message,
            type: "success",
            position: "top-left",
          });
        }
        this.getStorage();
        this.$store.dispatch("userInfo");
      } catch (e) {
        console.log(e);
      }
    },
    itemOne(item) {
      if (item.back_message != "") {
        this.ReturnReason = true;
        this.back_message = item.back_message;
      }
      item.isShow = !item.isShow;
    },
    changePassword() {
      this.passwordShow = true;
    },
    async changePasswordClick() {
      try {
        this.form.mobile = this.user_info.mobile;
        let { message } = await $api.getpassword(this.form);
        this.$notify({
          message: message,
          position: "top-left",
        });
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async getSet_inviter() {
      try {
        let { message, code } = await $api.getSet_inviter({
          invite_code: this.invite_code,
        });
        if (code == 500) return;
        if (code == 200) {
          this.$notify({
            title: "成功",
            message: message,
            type: "success",
            position: "top-left",
          });
        }
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async getCode() {
      let data = await $api.getSendSms({
        mobile: this.user_info.mobile,
        is_use: 2,
      });
      if (data.code == 500) return;
      this.$notify({
        title: "成功",
        message: data.message,
        type: "success",
        position: "top-left",
      });
      this.getCodeSeond = 60;
      let time = setInterval(() => {
        this.getCodeSeond -= 1;
        if (this.getCodeSeond == 0) {
          this.getCodeSeond = "获取验证码";
          clearInterval(time);
        }
      }, 1000);
    },
    async userSrc(response) {
      try {
        this.$common.blobToDataURL(response, async (res) => {
          let { data } = await $api.getImageBase64({ data: res });
          let { message, code } = await $api.getSet_avatar({ path: data.url });
          if (code == 500) return;
          this.$notify({
            title: "成功",
            message: message,
            type: "success",
            position: "top-left",
          });
          this.$store.dispatch("userInfo");
        });
      } catch (e) {
        console.log(e);
        return;
      }
    },
    authCopy() {
      console.log(this.InvitationCode);
      this.$common.CopyText({ content: this.InvitationCode });
    },
    async getSteamLink() {
      try {
        let { message, code } = await $api.getSet_steam_url({
          steam_url: this.steam_url,
        });
        if (code == 500) return;
        this.$notify({
          title: "成功",
          message: message,
          type: "success",
          position: "top-left",
        });
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async set_name() {
      try {
        let { message, code } = await $api.getSet_name({ name: this.name });
        if (code == 500) return;
        this.$notify({
          title: "成功",
          message: message,
          type: "success",
          position: "top-left",
        });
        this.$store.dispatch("userInfo");
      } catch (e) {
        console.log(e);
      }
    },
    async beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      const isImage = file.type.indexOf("image");
      if (!isLt5M) {
        this.$notify({
          message: "图片大于5M,请压缩图片",
        });
        return false;
      }
      if (isImage === -1) {
        this.$notify({
          message: "请上传图片格式,jpg或者png",
          position: "top-left",
        });
        return false;
      }
      this.$common.blobToDataURL(file, async (res) => {
        this.$nextTick(() => {
          this.option.img = res;
          this.previews.url = res;
          this.dialogVisible = true;
        });
      });
      return false;
    },
    finish() {
      this.$refs.cropper.getCropData(async (data) => {
        let { data: url } = await $api.getImageBase64({ data: data });
        let { message } = await $api.getSet_avatar({ path: url.url });
        this.$notify({
          title: "成功",
          message: message,
          type: "success",
          position: "top-left",
        });
        this.$store.dispatch("userInfo");
        setTimeout(() => {
          this.dialogVisible = false;
          this.loading = false;
        }, 1000);
      });
    },
    realTime(data) {
      this.previews = data;
    },
    openNickNameDialog() {
      this.showNickName = true;
    },
    openPasswordDialog() {
      this.showpassword = true;
    },
    openInviterDialog() {
      this.showInviter = true;
    },
  },
};
</script>

<style scoped lang="scss">
.headtitle {
  text-align: center;
  color: RGBA(5, 55, 123, 1);
  -webkit-text-stroke: 1px #a0db35;
}

.headimg {
  display: flex;
  justify-content: center;
}

.headimg>img {
  border-radius: 50%;
}

.edit-icon {
  width: 24px;
  height: 24px;
  position: relative;
  bottom: -20px;
  right: -30px;
}

.setting-box {
  padding: 0 16px;
  font-size: 16px;

  .setting-item {
    display: flex;
    justify-content: space-between;
    height: 45px;
    line-height: 45px;
    margin-top: 15px;
    background: linear-gradient(60deg, #1996e4 0%, #05377B 100%);
    border-radius: 15px;
    padding: 0 10px;

    div:nth-child(1) {
      width: 60px;
      font-weight: 600;
      font-style: italic;
    }

    div:nth-child(2) {
      width: 80%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.cropper-content {
  & .cropper {
    width: 300px;
    height: 300px;
  }
}

.nick-name-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 215px;
  background: rgba(5, 55, 123, 0.6);
  border-radius: 10px;
  backdrop-filter: blur(3px);

  .icon-cross {
    position: absolute;
    top: 30px;
    left: 264px;
  }

  input[type=text]::placeholder {
    color: white;
  }

  .change-nick-name {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 107px;
    height: 25px;
    margin: 47px auto;
    font-size: 24px;
    -webkit-text-stroke: 1px white;
    color: RGBA(5, 55, 123, 1);
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 82px auto;
    width: 228px;
    height: 45px;
    border-radius: 10px;
    opacity: 1;
    border: 1px solid #9fda35;
    padding-left: 10px;
    font-size: 14px;
  }

  button {
    width: 228px;
    height: 37px;
    background-color: #009fff;
    border-color: #009fff;
    border-radius: 10px;
    opacity: 1;
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 150px auto;
  }
}

.password-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 400px;
  background: rgba(5, 55, 123, 0.8);
  border-radius: 10px;
  backdrop-filter: blur(3px);

  .cross-icon {
    position: absolute;
    top: 19px;
    left: 266px;
  }

  .change-password {
    position: absolute;
    top: 20px;
    left: 97px;
    width: 114px;
    height: 25px;
    font-size: 30px;
    color: RGBA(5, 55, 123, 1);
    -webkit-text-stroke: 1px white;
  }

  input[type=text]::placeholder {
    color: white;
  }

  .telephone {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 95px auto;
    width: 228px;
    height: 45px;
    border-radius: 10px;
    opacity: 1;
    border: 1px solid #9fda35;
    padding-left: 10px;
    font-size: 14px;
  }

  .code {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 150px auto;
    width: 228px;
    height: 45px;
    border-radius: 10px;
    opacity: 1;
    border: 1px solid #9fda35;
    padding-left: 10px;
    font-size: 14px;

    .input-code {
      line-height: 45px;
    }

    .get-code {
      color: #9fda35;
      margin-left: 52px;
    }
  }

  .input-password {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 218px auto;
    width: 228px;
    height: 45px;
    border-radius: 10px;
    opacity: 1;
    border: 1px solid #9fda35;
    padding-left: 10px;
    font-size: 14px;
  }

  .confirm-password {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 274px auto;
    width: 228px;
    height: 45px;
    border-radius: 10px;
    opacity: 1;
    border: 1px solid #9fda35;
    padding-left: 10px;
    font-size: 14px;
    color: #fff;
  }

  button {
    width: 228px;
    height: 37px;
    background-color: #009fff;
    border-color: #009fff;
    border-radius: 10px;
    opacity: 1;
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 343px auto;
  }
}

.bind-inviter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 215px;
  background: rgba(5, 55, 123, 0.6);
  border-radius: 10px;
  backdrop-filter: blur(3px);

  .icon-cross {
    position: absolute;
    top: 30px;
    left: 264px;
  }

  .bind-invition {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 130px;
    height: 25px;
    margin: 32px auto;
    font-size: 28px;
    -webkit-text-stroke: 1px white;
    color: RGBA(4, 73, 146, 1);
  }

  input[type=text]::placeholder {
    color: white;
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 82px auto;
    width: 228px;
    height: 45px;
    border-radius: 10px;
    opacity: 1;
    border: 1px solid #9fda35;
    padding-left: 10px;
    font-size: 14px;
  }

  button {
    width: 228px;
    height: 37px;
    background-color: #009fff;
    border-color: #009fff;
    border-radius: 10px;
    opacity: 1;
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 150px auto;
  }
}

.side-box {
  width: 204px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.content {
  width: 50%;
  margin: 0 auto;
}

.input-height {
  height: 38px;
  line-height: 38px;
  width: 150px;
  text-align: center;
}

.input-width {
  width: 300px;
}

.login {
  &-left {
    margin-left: 30px;
    width: 170px;
  }

  &-right {
    margin-right: 30px;
    width: 770px;

    &-input {
      color: #ffffff;
      font-size: 25px;
    }
  }
}</style>
