
<template>
  <div>
    <div>
      <div class="record mt-3">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%">
          <el-table-column
            prop="bean"
            label="变动金额"
            width="">
          </el-table-column>
          <el-table-column
            prop="final_bean"
            label="账户余额"
            width="">
          </el-table-column>
          <el-table-column
            prop="type_alias"
            label="订单号">
          </el-table-column>
          <el-table-column
            prop="change_type_alias"
            label="消费方式">
          </el-table-column>
        </el-table>
        <div class="mt-3">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            @current-change="currentChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import $api from '@/api/user/index'
  export default {
    name: "income",
    props:{

    },
    data(){
      return {
        tableData:[],
        pageSize:0,
        total:0,
      }
    },
    filters:{
      
    },
    mounted() {
      this.change_typeCick('')
    },
    methods:{
      currentChange(val){
        this.change_typeCick(this.type,val)
      },
      // 充值记录
      async change_typeCick(item,page = 1){
          try {
            let {data, code} = await $api.getUsetChange_records(page,item)
            if (code == 500) return
            this.tableData = data.data
            this.total = data.total
            this.pageSize = data.per_page
          } catch (e) {
            console.log(e)
            return
          }
      },
   
    }
  }
</script>

<style scoped>
.active{
  background: var(--main-yellow);
  color: var(--main-brown);
}

</style>
