<template>
  <!-- 用户点击的按钮，基本公共样式 -->
  <div class="cursor-pointer openbnt" style="">
    <div :style="size" class="text-center">
      <slot>
        <!-- 默认返回这个，如果外面有插槽显示插槽内的内容 -->
        <div class="text" :style="location">
          {{ title }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomBg",
  props: {
    // 按钮样式
    size: {
      type: Object,
      default() {
        return {};
      },
    },
    // 按钮文字
    title: { type: String, default: "文字title" },
    // 按钮位置
    location: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.text {
  text-align: center;
  color: #ffffff;
  line-height: 40px;
}
.openbnt {
  text-align: center;
  background-image: url('../assets/image/6.png');
  width: 85px;
  height: 85px;
  line-height: 85px;
  background-size: 100% 100%;
  display: flex !important;
  margin: auto !important;
  justify-content: center !important;
}
</style>
