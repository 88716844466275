<template>
<div>
  <div>
    <div class="record mt-3">
      <el-table
        :data="tableData"
        style="width: 100%;color: #fff">
        <el-table-column
          prop="bean"
          label="充值金额"
          width="">
        </el-table-column>
        <el-table-column
          prop="price"
          label="充值价格"
          width="">
        </el-table-column>
        <el-table-column
          prop="code"
          label="订单号">
        </el-table-column>
        <el-table-column
          prop="status_alias"
          label="状态">
        </el-table-column>
      </el-table>
      <div class="mt-3">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    name: "pay",
    props:{
      tableData:{
        type:Array,
        default(){return []}
      },
      total:{type:Number || String,default() {
          return '';
        }},
      pageSize:{type:Number || String,default() {
          return '';
        }}
    },
    methods:{
      currentChange(val){
        this.$emit('currentChange',val)
      }
    }
  }
</script>

<style scoped>

</style>
